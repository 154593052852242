<template>
  <div class="posts-list">

    <!-- Authors Table -->
    <a-row :gutter="24" type="flex">

      <!-- Authors Table Column -->
      <a-col :span="24" class="mb-24">

        <a-card :bordered="true" class="header-solid h-full" >
          <template #title>
            <a-row type="flex" align="middle">
              <a-col :span="24" :md="12">
                <h5 class="font-semibold m-0">
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>Reset All</span>
                    </template>
                    <a-button type="link" @click="resetTable" class="icon-btn" style="color: black">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                           stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                           class="feather feather-refresh-cw">
                        <polyline points="23 4 23 10 17 10"></polyline>
                        <polyline points="1 20 1 14 7 14"></polyline>
                        <path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"></path>
                      </svg>
                    </a-button>
                  </a-tooltip>
                  {{capitalizeFirstLetter(status)}} Video Clips List {{pagination.total?'('+pagination.total+')':''}}
                </h5>
              </a-col>
              <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
                <router-link :to="{name:'Create Video Clip'}">
                  <a-button type="primary">
                    <a-icon type="plus-circle" theme="outlined"/>
                    ADD NEW
                  </a-button>
                </router-link>
              </a-col>
            </a-row>
            <a-row type="flex" align="middle" style="margin-top: 12px">
<!--              <a-col :span="7">-->
<!--                <a-select-->
<!--                    style="width: 100%"-->
<!--                    show-search-->
<!--                    allow-clear-->
<!--                    placeholder="Created By"-->
<!--                    option-filter-prop="children"-->
<!--                    :filter-option="filterUserOption"-->
<!--                    :loading="loadingUsers"-->
<!--                    v-model="user"-->
<!--                    @change="pagination.current=1,$router.push({name:'Events',query:{search:$route.query.search,status:$route.query.status,user:user}})"-->
<!--                >-->
<!--                  <a-select-option  :label="user.name" :value="user._id" v-for="(user,index) in getAllAdminUsers" :key="index">-->
<!--                                  <span style="margin-right: 5px;align-items: center" role="img" >-->
<!--                                     <a-avatar v-if="user.imageUrl" slot="avatar" :src="user.imageUrl" />-->
<!--        <a-avatar v-else slot="avatar" style="background-color: #1890ff">{{ user.name|getNameForAvatar}}</a-avatar>-->
<!--      </span>-->
<!--                    {{user.name}}-->
<!--                  </a-select-option>-->
<!--                </a-select>-->
<!--              </a-col>-->
              <a-col :span="12" >

              </a-col>
              <a-col :span="12"  style="display: flex; align-items: center; justify-content: flex-end">
                <a-input-search placeholder="Search Video Title" v-model="search" @input="loadingSearch=true,pagination.current=1,$router.push({name:'Video Clips',query:{search:search,status:$route.query.status}})" :loading="loadingSearch" />
              </a-col>
            </a-row>
          </template>
          <a-radio-group default-value="physical" @change="pagination.current=1,$router.push({name:'Video Clips',query:{status:status,search:$route.query.search}})" button-style="outline" style="margin-bottom:10px" v-model="status">
            <a-radio-button value="All">
              All
            </a-radio-button>
            <a-radio-button value="published">
              Published
            </a-radio-button>
            <a-radio-button value="draft">
              Draft
            </a-radio-button>
            <a-radio-button value="inactive">
              Inactive
            </a-radio-button>
            <a-radio-button value="deleted">
              Deleted
            </a-radio-button>
          </a-radio-group>
          <template>
            <a-list :loading="loading" item-layout="vertical" @change="handleTableChange" size="large" :pagination="pagination" :data-source="listData">

              <a-list-item slot="renderItem" key="item.title"  slot-scope="item, index">
                <template v-if="item.status=='Published'||item.status=='published'||item.status=='Inactive'||item.status=='inactive'" slot="actions" style="margin-left: 5px;">
                  {{ item.viewsCount?item.viewsCount:'No' }} {{ item.viewsCount==1?'View':'Views' }}
                </template>
                <template v-if="item.status=='Published'||item.status=='published'||item.status=='Inactive'||item.status=='inactive'" slot="actions" style="margin-left: 5px;">
                  {{ item.likesCount?item.likesCount:'No' }} {{ item.likesCount==1?'Like':'Likes' }}
                </template>
                <template v-if="item.status=='Published'||item.status=='published'||item.status=='Inactive'||item.status=='inactive'" slot="actions" style="margin-left: 5px;">
                  {{ item.commentsCount?item.commentsCount:'No' }} {{ item.commentsCount==1?'Comment':'Comments' }}
                </template><template v-if="item.status=='Published'||item.status=='published'||item.status=='Inactive'||item.status=='inactive'" slot="actions" style="margin-left: 5px;">
                {{ item.bookmarksCount?item.bookmarksCount:'No' }} {{ item.bookmarksCount==1?'Bookmark':'Bookmarks' }}
              </template><template v-if="item.status=='Published'||item.status=='published'||item.status=='Inactive'||item.status=='inactive'" slot="actions" style="margin-left: 5px;">
                {{ item.reportsCount?item.reportsCount:'No' }} {{ item.reportsCount==1?'Report':'Reports' }}
              </template>
                <template slot="actions" style="margin-left: 5px;">
                  Modified {{ moment(item.updatedAt).fromNow() }}
                </template>
                <a-list-item-meta style="position: relative">
                  <div slot="title" style="display: flex">
                    <router-link class="event-title"  :to="{

        name: 'Edit Video | Publish/Summary',
        params: { id: item._id }
      }" >
                   <span @click="$store.commit('RESET_SELECTED_POST')">
                     {{ item.title }}</span> <span style="color:rgba(0, 0, 0, 0.45);font-weight: 600;">#{{item.postNumber}}</span>
                    </router-link>

                    <div style="margin-left: 10px">
                      <a-dropdown>
                        <a-icon type="more" :style="{'cursor':'pointer','font-size':'18px','font-weight':'bold'}" />
                        <a-menu slot="overlay" @click="">
                          <a-menu-item key="1"> <router-link :to="{

        name: 'Edit Video | Basic Info',
        params: { id: item._id }
      }" target= '_blank'><span @click="$store.commit('RESET_SELECTED_POST')">
                            Edit</span> </router-link></a-menu-item>
                        </a-menu>
                      </a-dropdown>


                    </div>
                  </div>
                </a-list-item-meta>

                <div v-if="item">
                  <a-icon type="calendar" style="margin-right: 8px" />
                  <span style="margin-right: 12px">{{ item.date | moment("D MMM YYYY, hh:mm A") }} </span>
                  <span v-if="item.address">
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>
                  {{(item.address.post_code + ', ' + item.address.district + ', ' + item.address.country) }}
                </span>
                </div>
                <div class="table-avatar-info" style="align-items: center;margin-top: 0.6rem">
                  <a-avatar shape="square" :src="url+item.collectionDetail.image" style="margin-right: 8px;"></a-avatar>
                  <div class="avatar-info">
                    <h6 style="max-width: 145px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;">{{ item.collectionDetail.title }}</h6>
                  </div>
                  <div class="avatar-info">
                    <a-tooltip title="Related Event">
                    <h6  v-if="item.event" style="margin:0 5px 3px 20px;max-width: 455px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;line-height: 22px">
                      <a-icon type="calendar" style="margin-right: 6px" />
                      <router-link class="event-title"  :to="{

        name: 'Edit Event | Basic Info',
        params: { id: item.event }
      }" >
                        {{ item.eventDetail.title }} <span style="color:rgba(0, 0, 0, 0.45);font-weight: 600">#{{item.eventDetail.eventNumber}}</span>
                      </router-link>
                    </h6>
                    </a-tooltip>
                    <h6 v-if="!item.event" style="margin:0 5px 3px 20px;max-width: 455px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;">
                      <a-icon type="calendar" style="margin-right: 6px" />
                      <a-tag size="small" color="red">
                        No event selected
                      </a-tag>
                    </h6>
                  </div>
                </div>
                <p style="color: rgba(0, 0, 0, 0.45);
    font-size: 14px;font-weight: 600;
    margin: 0.8rem 0 0.5rem 5px;">
                  By {{ item.user.name }} on {{ item.createdAt | moment("MMM D YYYY, hh:mm A") }}
                  <a-tag class="tag-status ant-tag-primary" :class="{'ant-tag-success':item.status=='published'||item.status=='Published','ant-tag-danger':item.status=='deleted'||item.status=='Deleted','ant-tag-warning':item.status=='Inactive'||item.status=='inactive'}" style="margin-left: 10px">
                    {{ (item.status) | capitalizeFirstLetter }}
                  </a-tag>
                </p>
<!--                <video slot="extra"  controls="" controlslist="nodownload" crossorigin="anonymous" preload="preload" airplay="allow" x-webkit-airplay="allow" playsinline="" class="cge-video header-video" poster="https://aimanepal.postgrill.com/img/video-preload-min.webp"-->
<!--                       style="object-fit: contain; zoom: 1.83016;width: 272px;height: 180px">-->
<!--                  <source :src="item.videoDetail?(item.videoDetail.type=='video_source'?item.videoDetail.sourceUrl:url+item.videoDetail.uploadPath):'images/no-image.jpg'" type="video/mp4">-->

<!--                </video>-->
<!--                <video width="272" height="180" slot="extra" style="border-radius: 5px;"-->
<!--                        :src="item.videoDetail?(item.videoDetail.type=='video_source'?item.videoDetail.sourceUrl:url+item.videoDetail.uploadPath):'images/no-image.jpg'">-->
<!--                </video>-->
<!--                <iframe-->
<!--                    slot="extra"-->
<!--                    width="272"-->
<!--                    style="height: 180px;-->
<!--    object-fit: cover;"-->
<!--                    :src="item.videoDetail?(item.videoDetail.type=='video_source'?item.videoDetail.sourceUrl:url+item.videoDetail.uploadPath):'images/no-image.jpg'"-->
<!--                />-->
<!--                <iframe slot="extra" width="272" style="height: 180px; object-fit: cover;">-->
<!--                  <video poster="https://aimanepal.postgrill.com/img/video-preload-min.webp">-->
<!--                    <source :src="item.videoDetail?(item.videoDetail.type=='video_source'?item.videoDetail.sourceUrl:url+item.videoDetail.uploadPath):'images/no-image.jpg'" type="video/mp4">-->
<!--                  </video>-->
<!--                </iframe>-->
                <video slot="extra" width="272" height="180" controls
                       :poster="item.videoDetail&&item.videoDetail.thumbnail?(item.videoDetail.thumbnail.type=='image_source'?item.videoDetail.thumbnail.sourceUrl:url+item.videoDetail.thumbnail.uploadPath):'images/no-image.jpg'">
                  <source :src="item.videoDetail?(item.videoDetail.type=='video_source'?item.videoDetail.sourceUrl:url+item.videoDetail.uploadPath):'images/no-image.jpg'" type="video/mp4">

                </video>
              </a-list-item>
            </a-list>
          </template>


        </a-card>


      </a-col>

    </a-row>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import moment from 'moment';

import {mapGetters} from 'vuex'
const listData = [];

export default {
  data() {
    this.fetchEventDebounce = debounce(this.fetchEventDebounce, 800);
    return {
      moment,
      loadingSearch:false,
      search:'',
      listData,
      pagination: {
        onChange: page => {
          this.$router.push({name:'Video Clips',query:{page:page,search:this.$route.query.search,status:this.$route.query.status}})
          this.handleTableChange(page)
        },
        current:this.$route.query.page?this.$route.query.page:1,
        pageSize: 10,
      },
      status:'All',
      url: process.env.VUE_APP_SERVER_URL,
      filteredInfo: null,
      sortedInfo: null,
      searchText: "",
      searchInput: null,
      selectedItem: {},
      showModal: false,
      editMode: false,
      data: [],
      loading: false,
      authorsHeaderBtns: 'all',
      queryData: params => {
        params.type='video'
        return this.$store.dispatch("ALL_POSTS", {params: params});
      }
    };
  },
  watch:{
    '$route.query.status'(){
      this.fetch()
    },
    '$route.query.search'(){
      this.fetchEventDebounce()
    }
  },
  mounted() {
    this.status=this.$route.query.status?this.$route.query.status:'All'
    this.search=this.$route.query.search?this.$route.query.search:''

    this.fetch();
  },
  methods: {
    fetchEventDebounce(){
      this.fetch()
    },
    handleTableChange(page) {
      const pager = {...this.pagination};
      pager.current = page;
      this.pagination = pager;
      // this.filteredInfo = filters;
      // this.sortedInfo = sorter;
      this.fetch({
        results: this.pagination.pageSize,
        page: this.pagination.current,
        // sortField: sorter.field,
        // sortOrder: sorter.order,
        // ...filters,
      });
    },
    fetch(params = {}) {
      this.loading = true;
      params.status=this.status
      params.search=this.search
      this.queryData({
        results: this.pagination.pageSize ? this.pagination.pageSize : 10,
        page: this.pagination.current ? this.pagination.current : 1,
        ...params,
      }).then(({data}) => {
        const pagination = {...this.pagination};
        // Read total count from server
        // pagination.total = data.totalCount;
        pagination.total = data.total;
        this.loading = false;
        this.listData = data.results;
        this.pagination = pagination;
      })
          .finally(()=>{
            this.loadingSearch=false
          });
    },
    showDelete(id) {
      this.$confirm({
        title: 'Are you sure want to delete this collection?',
        content: 'This process is irreversible. Are you sure want to continue?',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: () => {
          this.$store.dispatch("DELETE_COLLECTION", id)
              .then((res) => {
                this.$message.success(res.data.message);
                this.fetch()
              })
              .catch((err) => {
         //       this.$message.error(err.response.data.message);
              })
              .finally(() => {
              });
        },
      });
    },
    showEditForm(row) {
      this.$router.push({
        name: 'Edit Event | Basic Info',
        params: { id: row._id }
      });

    },
    formSuccess() {
      this.showModal = false;
      this.editMode = false
      this.selectedItem = {}
      this.fetch()
    },
    cancelForm() {
      this.showModal = false;
      this.editMode = false
      this.selectedItem = {}
    },
    handleSearch(selectedKeys, confirm) {
      confirm();
      this.searchText = selectedKeys[0];
    },
    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    resetTable() {
      // this.handleReset()
      // this.searchText = "";
      // this.filteredInfo = null;
      // this.sortedInfo = null;
      // this.pagination.current = 1;
      // this.pagination.pageSize = 10;
      // Object.assign(this.pagination,{})
      this.fetch()
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  },
};
</script>
<style >
.posts-list .editable-row-operations a {
  margin-right: 8px;
}

.posts-list .custom-filter-dropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.posts-list .highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
.posts-list .ant-radio-group .ant-radio-button-wrapper{
  padding: 0 32px;
}
.posts-list .ant-radio-button-wrapper:focus-within{
  box-shadow: none;
}
.posts-list .ant-list-vertical .ant-list-item-meta{
  margin-bottom: 0;
}
.posts-list .table-avatar-info .avatar-info h6{
  color: rgba(0, 0, 0, 0.65);
  font-weight: 600;
}
.posts-list  .ant-spin-nested-loading{
  min-height: 300px!important;
}
.posts-list .ant-list-item-action{
  font-weight: 600;
}
.posts-list .ant-list-item-action > li{
  font-weight: 600!important;
}
.posts-list .ant-list-vertical .ant-list-item-action{
  margin-left: 5px;
}
.posts-list .ant-list-vertical .ant-list-item-meta-title{
  margin-bottom: 6px;
}
.event-title{
  color: rgba(0, 0, 0, 0.65);
}
.ant-list-vertical .ant-list-item-action > li{
  padding: 0 5px;
}
</style>
